<template>
  <base-section
    id="compliance"
    space="36"
  >
    <v-container>
      <base-section-heading title="Our Product Compliance" />
      <v-row>
        <v-col
          v-for="number in 4"
          cols="3"
          :key="number"
        >
            <base-img
              :src="require(`@/assets/compliance-${number}.png`)"
              color="grey"
              contain
              height="90"
              width="120"
              class="mx-auto"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="number in 3"
          cols="4"
          :key="number"
        >
            <base-img
              :src="require(`@/assets/compliance-${number+4}.png`)"
              color="grey"
              contain
              height="90"
              width="120"
              class="mx-auto"
            />
        </v-col>
      </v-row>
      <br>
      <br>
      <br>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
        <base-subheading
            title="FACILITY REGISTRATION"
            size="text-h5"
            mobileSize="text-h6"
            class="text-center"
          />
          <br>
          <base-img
            :src="require(`@/assets/complianceTwo-1.png`)"
            color="grey"
            contain
            height="120"
            class="mx-auto"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <base-subheading
            title="QUALITY MANAGEMENT SYSTEM CERTIFICATION"
            size="text-h5"
            mobileSize="text-h6"
            class="text-center"
          />
          <br>
          <base-img
            :src="require(`@/assets/complianceTwo-2.png`)"
            color="grey"
            contain
            height="120"
            class="mx-auto"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCompliance',
  }
</script>
